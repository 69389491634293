<template>
  <router-link :to="{ name: 'share', params: { id: data.id } }" :title="data.title" class="shares__item">
    <div class="ratio-container">
      <ImgComponent class="shares__item-img" :img="data.card_img" :lazy="false" width="800" height="600" />
    </div>
  </router-link>
</template>

<script>
export default {
  name: "ShareComponent",
  props: {
    data: Object,
  },
};
</script>

<style lang="stylus">
@import "~@/styles/mixins/ratio.styl"
.shares {
  &__item {
    height: 213px
    ratio(320, 213)

    &-img {
      border-radius var(--big-radius)
      width 100%
      height 100%
      transition var(--transition)
      overflow: hidden;

      &:hover {
        filter: brightness(.86);
      }
    }
  }
}
</style>
