<template>
  <div class="doctor-referral">
    <h2 class="title">
      Есть <br />
      направление <br />
      врача?
    </h2>
    <span class="doctor-referral__subtitle">Консультанты подберут ортопедию по вашему направлению</span>
    <button @click="showReferralModal" class="btn btn--md btn--blue" type="button">
      <IconComponent name="document-download" />
      <span>Загрузить направление</span>
    </button>
  </div>
</template>

<script>
import IconComponent from "components/IconComponent.vue";
import LoadReferralModal from "components/modals/components/LoadReferralModal.vue";

export default {
  name: "DoctorReferralComponent",
  components: { IconComponent },
  methods: {
    showReferralModal() {
      this.$store.state._modals.push({
        component: LoadReferralModal,
        options: {},
      });
    },
  },
};
</script>

<style lang="stylus">
.doctor-referral {
  display flex
  flex-direction column
  justify-content flex-end
  align-items flex-start
  gap: 15px
  padding 40px
  border-radius: var(--big-radius)
  background linear-gradient(184.97deg, rgba(255, 255, 255, 0) 38.09%, rgba(255, 255, 255, 0.9) 97.37%), url("/static/images/tmp/referral.jpg")
  background-repeat no-repeat
  background-position center
  background-size cover
  +below(620px) {
    min-height 400px
  }
  +below(540px) {
    padding 15px
  }

  .title {
    margin 0
    color: var(--main);
    line-height 1.35
    max-width 270px
  }

  &__subtitle {
    font-size: 1.125em
    line-height: 1.55
    +below(440px) {
      font-size: 0.875em
      line-height: 22px;
    }
  }

  .btn {
    +below(440px) {
      width: 100%
    }
  }
}
</style>
