<template>
  <div class="orthopedist-banner">
    <div class="orthopedist-banner__inner">
      <span class="orthopedist-banner__title">
        Приём врача <br />
        ортопеда
      </span>
      <span class="orthopedist-banner__subtitle">
        Хотите забыть о боли, дискомфорте и усталости, всегда быть в форме и отличном настроении? Хорошо, что
        есть Ортосити!
      </span>
      <button @click="showModal" class="btn btn--md btn--blue">Записаться на приём</button>
    </div>
  </div>
</template>

<script>
import OrthopedistModal from "components/modals/components/OrthopedistModal.vue";
export default {
  name: "OrthopedistBannerComponent",
  methods: {
    showModal() {
      this.$store.state._modals.push({
        component: OrthopedistModal,
        options: {},
      });
    },
  },
};
</script>

<style lang="stylus">
.orthopedist-banner {
  width 100%
  height 500px
  background url("/static/images/banner_big.jpg")
  background-repeat no-repeat
  background-size cover
  border-radius: var(--big-radius)
  display flex
  +below(1200px) {
    height 400px
  }
  +below(768px) {
    background-image url("/static/images/banner_big_mobile.jpg")
    background-position center top
    height 600px
  }
  +below(580px) {
    height 540px
  }
  +below(540px) {
    height 480px
  }

  &__inner {
    width 100%
    display flex
    flex-direction column
    align-items flex-start
    justify-content center
    gap: 15px
    padding 115px 68px
    +below(1150px) {
      padding 75px 40px
    }
    +below(840px) {
      padding 40px
    }
    +below(768px) {
      justify-content flex-end
      padding 25px
    }
    +below(390px) {
      padding 15px
    }
  }

  &__title {
    font-family var(--font-bold)
    font-size: 2.125em
    line-height: 1.35
    font-weight 900
    text-transform: uppercase;
    color: var(--main);
    max-width 420px
    +below(540px) {
      font-size 1.5em
    }
  }

  &__subtitle {
    font-size: 1.125em
    line-height: 1.55
    max-width 380px
    +below(540px) {
      font-size: 1em
    }
  }
}
</style>
