<template>
  <component :is="tag" class="link-more" :to="route">
    <span>{{ title }}</span>
    <IconComponent name="arrow" />
  </component>
</template>

<script>
import IconComponent from "components/IconComponent.vue";
export default {
  name: "LinkMoreComponent",
  components: { IconComponent },
  props: {
    title: {
      type: String,
      default: "Подробнее",
    },
    route: {
      type: Object,
      default() {
        return { name: "" };
      },
    },
  },
  computed: {
    tag() {
      return this.route.name ? "router-link" : "span";
    },
  },
};
</script>

<style lang="stylus">
.link-more {
  display: flex;
  align-items: center;
  gap: 10px;
  color: var(--dark-light);
  +below(440px) {
    font-size 0.875em
  }

  .icon {
    width 32px
    max-width 32px
    height auto
  }
}
</style>
