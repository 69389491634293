<template>
  <div class="file-upload__container">
    <span class="file-upload__title" v-if="title">{{ title }}</span>
    <label
      :class="{
        'file-upload--disabled': disabled,
        'file-upload--highlight': highlight && !disabled,
        'file-upload--loading': loading,
      }"
      class="file-upload"
      @dragenter="dragOver"
      @dragleave="dragLeave"
      @dragover="dragOver"
      @drop="drop"
    >
      <img v-if="files && !loading" :src="files | image($store.state._env.MEDIA_ENDPOINT)" alt="" />
      <template v-else>
        <span class="file-upload__loading" v-if="loading">
          <span class="file-upload__loading-progress" :style="`width: ${progress}%`"></span>
          <IconComponent name="loading" />
        </span>
        <span class="file-upload__placeholder" v-else>
          <span>Загрузите направление врача</span>
        </span>
      </template>
      <input
        v-show="false"
        :accept="accept"
        ref="input"
        :disabled="loading || disabled"
        type="file"
        @change="change"
      />
    </label>
  </div>
</template>

<script>
import FILES_UPLOAD from "@/graphql/mutations/FilesUpload.graphql";
import IconComponent from "components/IconComponent.vue";

export default {
  name: "VideoUploadComponent",
  components: { IconComponent },
  props: {
    uploaded: Object,
    disabled: Boolean,
    title: String,
    accept: {
      type: String,
      default: "image/*",
    },
  },
  data() {
    return {
      progress: 0,
      drag: false,
      highlight: false,
      over: false,
      loading: false,
      videoTitle: null,
      files: null,
      dragTimeout: null,
      highlightTimeout: null,
      image: null,
      regexp: {
        image: /image\/*/,
        video: /video\/*/,
      },
    };
  },
  watch: {
    uploaded() {
      if (this.uploaded) {
        this.videoTitle = this.uploaded.title;
        this.files = this.uploaded;
      }
    },
  },
  mounted() {
    if (this.uploaded) {
      this.videoTitle = this.uploaded.title;
      this.files = this.uploaded;
    }
    document.addEventListener("dragover", (event) => {
      event.preventDefault();
      event.stopPropagation();
    });
    document.addEventListener("dragover", (event) => {
      event.preventDefault();
      event.stopPropagation();
      this.drag = true;
    });
    document.addEventListener("dragleave", (event) => {
      event.preventDefault();
      event.stopPropagation();
      this.debouncedDelayedSetOfDrag(false);
    });
  },
  methods: {
    clear() {
      if (confirm("Вы уверены?")) {
        this.files.delete = true;
        this.videoTitle = null;
        this.$emit("change", this.files);
      }
    },
    debouncedDelayedSetOfDrag(value) {
      clearTimeout(this.dragTimeout);
      this.dragTimeout = setTimeout(() => {
        this.drag = value;
      }, 200);
    },
    debouncedDelayedSetOfHighlight(value) {
      clearTimeout(this.highlightTimeout);
      this.highlightTimeout = setTimeout(() => {
        this.highlight = value;
      }, 200);
    },
    dragLeave(event) {
      event.preventDefault();
      event.stopPropagation();
      this.debouncedDelayedSetOfHighlight(false);
    },
    dragOver(event) {
      event.preventDefault();
      event.stopPropagation();
      this.highlight = true;
    },
    drop(event) {
      event.preventDefault();
      const dt = event.dataTransfer;
      if (dt) {
        this.loading = true;
        this.debouncedDelayedSetOfHighlight(false);
        this.debouncedDelayedSetOfDrag(false);
        this.upload(dt.files);
      }
    },
    change(event) {
      if (!this.loading && !this.disabled) {
        this.loading = true;
        if (event.target.files.length) {
          this.upload(event.target.files);
        }
      }
    },
    upload(files) {
      if (files && files.length) {
        // let reader = new FileReader();
        // reader.readAsDataURL(files[0]);
        // reader.onload = (e) => {
        //   this.image = e.target.result;
        // };

        this.$apollo
          .mutate({
            mutation: FILES_UPLOAD,
            variables: {
              files: files,
            },
            context: {
              fetchOptions: {
                useUpload: true,
                onProgress: (e) => {
                  this.progress = (e.loaded / e.total) * 100;
                },
                onAbortPossible: () => {},
              },
            },
          })
          .then(({ data }) => {
            if (data.FilesUpload && data.FilesUpload.length) {
              this.files = data.FilesUpload[0];
            }
            this.loading = false;
            this.$emit("change", this.files);
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },
  },
};
</script>

<style lang="stylus">
@import "~@/styles/mixins/svg.styl"
@keyframes stripped {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: 31px 62px;
  }
}

.file-upload {
  display flex
  align-items center
  justify-content center
  flex-flow row wrap
  width 100%
  min-height 200px
  background var(--white)
  border: 1px dashed var(--border-color);
  border-radius 10px
  padding 5px
  gap 24px
  transition .2s
  cursor pointer
  position relative
  height 100%
  overflow hidden

  &::after {
    absolute left 50% right top 50% bottom
    width 100%
    height 100%
    transform translate(-50%, -50%)
    content ""
    background-image url("/static/svg/download.svg")
    background-repeat no-repeat
    background-position center
    background-size 60px 60px
    background-color var(--dark-o1)
    opacity 0
    visibility hidden
    transition var(--transition)
  }

  &--highlight:not(&--loading):not(&--disabled)
  &:not(&--loading):not(&--disabled):hover {
    border-color var(--main)

    &::after {
      opacity 1
      visibility visible
    }

    & ^[0]__placeholder {
      opacity 0
      visibility hidden
    }
  }

  &__title {
    font-size: 0.875em;
    font-family: 'Roboto-Regular';
    display: block;
    line-height: 1.5em;
    color: var(--gray);
  }

  &__container {
    display grid
    align-items start
    justify-content stretch
    grid-gap 5px
    height 100%
  }

  &__input {
    display flex
    flex-direction column
    align-items flex-start
    justify-content flex-start
    gap 10px
  }

  &__loading {
    position relative
    display flex
    align-items center
    justify-content center
    border-radius 10px
    absolute left top
    width 100%
    font-size 18px
    text-shadow 0 0 2px var(--gray)
    height 100%
    overflow hidden

    &-progress {
      absolute left top
      height 100%
      transition width .2s
      background-color var(--main-o1)
      background-size: 31px 31px;
      background-image: linear-gradient(-45deg, rgba(0, 0, 0, .1) 25%, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, .1) 0, rgba(0, 0, 0, .1) 75%, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0));
      animation: stripped 2s linear 0s infinite normal none running;
    }

    .icon {
      width 70px
      height 70px

      svg circle {
        stroke var(--dark)
      }
    }
  }

  &__list {
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 24px;
  }

  &__item {
    width 92px
    display flex
    flex-direction column
    justify-content flex-start
    align-items center
    font-weight: normal;
    font-size: 0.75em;
    line-height: normal;
    text-align: center;
    color: var(--gray);
    white-space: pre-wrap;
    word-wrap: break-word;
    gap 12px
    position relative
  }

  &__placeholder {
    display flex
    flex-direction column
    align-items center
    justify-content center
    gap 8px
    text-align: center;
    color: var(--dark-light);
    user-select none
    transition var(--transition)
  }

  img {
    width 100%
    height 100%
    object-fit contain
    object-position center
  }
}
</style>
