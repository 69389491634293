<template>
  <div
    v-if="products && products.length"
    class="products"
    :class="{ 'products--row': row }"
    :style="{ opacity: loading ? 0.7 : 1 }"
  >
    <ContentHeaderComponent :title="title" />
    <div ref="list" class="products__list">
      <ProductCardComponent
        :data="item"
        v-for="(item, i) in products"
        :key="'a' + i"
        :style="{ order: i + 1 }"
      />
      <BannerComponent
        class="products__banner"
        v-for="(item, i) in allBanners"
        :key="i"
        :data="item"
        :wide="!!item.wide_banner"
        :style="{ order: getOrder(item, i) }"
      />
    </div>
    <div v-if="allBanners && allBanners.length" class="products__banners--mobile">
      <BannerComponent
        v-for="(item, i) in allBanners"
        :key="i"
        :data="item"
        :wide="!!item.wide_banner"
        :style="{ order: getOrder(item, i) }"
      />
    </div>
  </div>
</template>

<script>
import ContentHeaderComponent from "components/ContentHeaderComponent.vue";
import ProductCardComponent from "components/product/ProductCardComponent.vue";
import BannerComponent from "components/BannerComponent.vue";

export default {
  name: "ProductsComponent",
  components: { BannerComponent, ProductCardComponent, ContentHeaderComponent },
  props: {
    title: String,
    products: Array,
    banners: {
      type: Array,
      default: () => [],
    },
    banners_wide: {
      type: Array,
      default: () => [],
    },
    reverse: Boolean,
    row: Boolean,
    loading: Boolean,
  },
  data() {
    return {
      cols: 4,
      breakpoints: {},
    };
  },
  mounted() {
    this.setCols();
  },
  computed: {
    allBanners() {
      let list = [];
      list.push(...this.banners);
      return list.concat(
        this.banners_wide.map((i) => {
          return {
            wide_banner: true,
            ...i,
          };
        })
      );
    },
  },
  methods: {
    getOrder(item, i) {
      let index;
      if (item.wide_banner) {
        index = 2;
      } else if (i === 0) {
        index = 1;
      } else if (i === 1) {
        index = 3;
      }
      return this.reverse ? this.cols * index : this.cols * index + this.cols - 2;
    },
    setCols() {
      let list = this.$refs.list;
      if (list && list.firstChild) {
        this.cols = Math.floor(
          list.getBoundingClientRect().width / list.firstChild.getBoundingClientRect().width
        );
        list.style.setProperty("--cols", this.cols);
      }
    },
  },
};
</script>

<style lang="stylus">
.products {
  &--row {
    & ^[0]__list {
      width 100%
      overflow auto
      +below(768px) {
        grid-auto-flow column
      }
    }

    & ^[0]__banner {
      +below(768px) {
        display none
      }
    }

    .product-card {
      +below(768px) {
        min-width 250px
      }
      +below(560px) {
        min-width 220px
      }
      +below(480px) {
        min-width 180px
      }
    }
  }

  &:not(&--row) &__banners--mobile {
    display none
  }

  &__list {
    display grid
    grid-template-columns repeat(auto-fit, minmax(280px, 1fr));
    grid-gap var(--gap)
    +below(768px) {
      grid-template-columns 1fr 1fr;
      grid-gap 20px
    }
    +below(540px) {
      grid-gap 15px
    }
    +below(400px) {
      grid-gap 10px
    }
    +below(340px) {
			grid-gap: 5px
    }
  }

  &__banners--mobile {
    margin-top var(--gap)
    display flex
    flex-direction column
    gap: var(--gap)
    +above(769px) {
      display none
    }
  }
}
</style>
