<template>
  <div v-if="categories && categories.length" class="categories">
    <ContentHeaderComponent :title="title" />
    <div class="categories__list">
      <router-link
        :to="{ name: 'catalog', params: { id: item.id } }"
        class="categories__item"
        v-for="(item, i) in categories"
        :key="i"
      >
        <ImgComponent :img="item.head_img" class="categories__item-img" />
        <div class="categories__item-info">
          <span class="categories__item-title">{{ item.title }}</span>
          <LinkMoreComponent />
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import ContentHeaderComponent from "components/ContentHeaderComponent.vue";
import LinkMoreComponent from "components/LinkMoreComponent.vue";

export default {
  name: "CategoriesComponent",
  props: {
    title: String,
    categories: Array,
  },
  components: { LinkMoreComponent, ContentHeaderComponent },
};
</script>

<style lang="stylus">
.categories {
  &__list {
    display grid
    grid-template-columns repeat(auto-fit, minmax(400px, 1fr));
    grid-gap var(--gap)
    +below(990px) {
      grid-gap 20px
    }
    +below(540px) {
      grid-gap 15px
      grid-template-columns 1fr
    }
  }

  &__item {
    background: var(--gray);
    border-radius: var(--big-radius)
    padding 20px
    display flex
    gap: 20px
    +below(990px) {
      padding 15px
    }
    +below(540px) {
      gap: 15px
    }

    &:hover {
      background var(--gray-darker)
    }

    &-img {
      flex-shrink 0
      width 130px
      height 130px
      border-radius: var(--big-radius)
      +below(990px) {
        width 110px
        height: 110px
      }
      +below(540px) {
        width: 80px
        height: 80px
      }
    }

    &-info {
      height 100%
      display flex
      flex-direction column
      justify-content space-between
      +below(540px) {
        justify-content flex-start
      }
    }

    &-title {
      font-weight: 700;
      font-size: 1.125em
      line-height: 1.5
      +below(440px) {
        font-size 1em
        line-height inherit
      }
    }

    &-more {
      display flex
      align-items center
      gap: 10px
      color var(--dark-light)
    }
  }
}
</style>
