<template>
  <div class="default-modal referral-modal">
    <div class="default-modal__header">
      <h3 class="default-modal__title">Загрузить направление</h3>
      <button type="button" class="default-modal__close" @click="$emit('close')">
        <IconComponent name="close-square" />
      </button>
    </div>
    <div class="default-modal__body referral-modal__body">
      <div v-if="!message" class="referral-modal__load">
        <div class="referral-modal__load-field">
          <FileUploadComponent @change="getFiles" />
        </div>
        <div class="referral-modal__input-fields">
          <InputComponent
            v-model="form.title.value"
            title="Имя"
            :subtitle="form.title.message"
            :error="form.title.error"
            modified
            required
          />
          <InputComponent
            v-model="form.phone.value"
            title="Телефон"
            :subtitle="form.phone.message"
            :error="form.phone.error"
            mask="+7 (###) ### - ## - ##"
            modified
            required
            is-phone
          />
          <button @click="submit" class="btn btn--md btn--red" type="button">
            <IconComponent v-if="loading" name="loading" />
            <span v-else>Оставить заявку</span>
          </button>
          <span class="referral-modal__terms">
            Нажимая на кнопку, вы соглашаетесь с <a href="/files/terms.pdf">условиями использования</a>
          </span>
        </div>
      </div>
      <span class="referral-modal__message" v-else>
        {{ message }}
      </span>
    </div>
  </div>
</template>

<script>
import IconComponent from "components/IconComponent.vue";
import InputComponent from "components/inputs/InputComponent.vue";
import FileUploadComponent from "components/inputs/FileUploadComponent.vue";
import CLAIM_CREATE from "@/graphql/mutations/ClaimCreate.graphql";

export default {
  name: "LoadReferralModal",
  data() {
    return {
      step: 0,
      message: null,
      loading: false,
      form: {
        title: {
          value: undefined,
          message: undefined,
          error: undefined,
        },
        phone: {
          value: undefined,
          message: undefined,
          error: undefined,
        },
        img: {
          value: undefined,
          message: undefined,
          error: undefined,
        },
      },
    };
  },
  methods: {
    getFiles(file) {
      if (file) {
        this.form.img.value = file;
        delete this.form.img.value.__typename;
      }
    },
    submit() {
      if (!this.loading) {
        this.loading = true;
        this.$apollo.provider.defaultClient
          .mutate({
            mutation: CLAIM_CREATE,
            variables: {
              title: this.form.title.value,
              phone: this.form.phone.value,
              img: this.form.img.value,
              type: this.$store.state._types.REFERRAL,
            },
            context: {
              headers: {
                Authorization: "Bearer " + this.$store.state.apollo_token,
              },
            },
          })
          .then(({ data }) => {
            if (data && data.ClaimCreate) {
              this.message = data.ClaimCreate.message;
            }
            this.loading = false;
            setTimeout(() => {
              this.$emit("close");
            }, 10000);
          })
          .catch(({ graphQLErrors }) => {
            this.loading = false;
            this.parseGqlErrors(graphQLErrors);
          });
      }
    },
    parseGqlErrors(graphQLErrors) {
      graphQLErrors.forEach((err) => {
        if (err.extensions.category === "validation") {
          Object.keys(err.extensions.validation).forEach((key) => {
            if (this.form[key]) {
              this.form[key].error = err.extensions.validation[key][0];
            }
          });
        }
      });
    },
  },
  components: { FileUploadComponent, InputComponent, IconComponent },
};
</script>

<style lang="stylus">
.referral-modal {
  width 100%
  max-width 700px

  &__body {
    align-items stretch
  }

  &__load {
    display grid
    grid-template-columns 1fr 1fr
    grid-gap var(--gap)
    +below(768px) {
      grid-template-columns 1fr
    }
  }

  &__load-field {
    height 300px
  }

  &__input-fields {
    display flex
    flex-direction column
    gap: 20px
  }

  &__terms {
    font-size: 0.75em
    line-height: 14px;
    color: var(--dark-light)

    a {
      border-bottom 1px solid
    }
  }

  &__message {
    padding 30px
    width 100%
    border-radius var(--big-radius)
    color var(--green-dark)
    background: var(--green-o05)
    border: 1px solid var(--green-o15)
    text-align center
  }
}
</style>
