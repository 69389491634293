import { render, staticRenderFns } from "./OrthopedistModal.vue?vue&type=template&id=9ac5f702"
import script from "./OrthopedistModal.vue?vue&type=script&lang=js"
export * from "./OrthopedistModal.vue?vue&type=script&lang=js"
import style0 from "./OrthopedistModal.vue?vue&type=style&index=0&id=9ac5f702&prod&lang=stylus"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports