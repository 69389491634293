<template>
  <a :href="data.url" v-if="data" class="banner" :class="{ 'banner--wide': wide, 'banner--big': big }">
    <div class="ratio-container">
      <ImgComponent v-show="img" :img="img" />
    </div>
  </a>
</template>

<script>
import OrthopedistModal from "components/modals/components/OrthopedistModal.vue";
export default {
  name: "BannerComponent",
  props: {
    data: Object,
    wide: Boolean,
    big: Boolean,
    btnTitle: String,
  },
  data() {
    return {
      img: null,
    };
  },
  mounted() {
    this.$nextTick(() => {
      if (typeof window !== "undefined") {
        this.img = window.innerWidth <= 640 ? this.data.mobile_img : this.data.img;
      }
    });
  },
  methods: {
    showModal() {
      this.$store.state._modals.push({
        component: OrthopedistModal,
        options: {},
      });
    },
  },
};
</script>

<style lang="stylus">
@import "~@/styles/mixins/ratio.styl"
.banner {
  width 100%
  background-repeat no-repeat
  background-position center
  background-size cover
  border-radius: var(--big-radius)
  overflow hidden
  display flex
  align-items flex-end
  +above(401px) {
    grid-column: auto / span 2;
  }

  .ratio-container {
    width 100%
  }

  &:not(&--wide) {
    +above(641px) {
      ratio(680, 532)
    }
  }

  &:hover {
    filter brightness(0.9)
  }

  &--wide {
    min-height 0
    background none
    +above(401px) {
      grid-column: 1 / -1;
    }

    img {
      width 100%
      height auto
      //max-height 532px
      object-fit contain
      object-position center
    }
  }

  &--big {
    align-items center
  }
}
</style>
