<template>
  <div v-if="shares && shares.length" class="shares">
    <ContentHeaderComponent :title="title" :route="{ name: 'shares' }" />
    <SliderComponent class="shares__slider" :items="shares" v-slot="{ item }" :slider-options="sliderOptions">
      <ShareComponent :data="item" />
    </SliderComponent>
  </div>
</template>

<script>
import ContentHeaderComponent from "components/ContentHeaderComponent.vue";
import SliderComponent from "components/SliderComponent.vue";
import ShareComponent from "components/ShareComponent.vue";
export default {
  name: "SharesComponent",
  components: { ShareComponent, SliderComponent, ContentHeaderComponent },
  props: {
    title: {
      type: String,
      default: "Акции",
    },
    shares: Array,
  },
  data() {
    return {
      sliderOptions: {
        slidesPerView: 1.2,
        spaceBetween: 15,
        pagination: false,
        clickable: false,
        preloadImages: true,
        breakpoints: {
          400: {
            slidesPerView: 2,
            spaceBetween: 15,
          },
          990: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
          1200: {
            slidesPerView: 4,
            spaceBetween: 40,
          },
        },
      },
    };
  },
};
</script>

<style lang="stylus">
@import "~@/styles/mixins/ratio.styl"
.shares {
  &__slider {
    .slider-arrows__item {
      svg path {
        fill var(--border-color)
      }

      &:hover {
        border-color var(--blue)
      }
    }
  }
}
</style>
