<template>
  <div class="default-modal orthopedist-modal" :class="{ 'orthopedist-modal--message': message }">
    <img class="orthopedist-modal__img" src="/static/images/orthopedist.jpg" alt="Запись ортопеда" />
    <img
      class="orthopedist-modal__img orthopedist-modal__img--mobile"
      src="/static/images/orthopedist_mobile.jpg"
      alt="Запись ортопеда"
    />
    <div class="orthopedist-modal__content">
      <div class="default-modal__header">
        <h3 class="default-modal__title">Запись ортопеда</h3>
        <button type="button" class="default-modal__close" @click="$emit('close')">
          <IconComponent name="close-square" />
        </button>
      </div>
      <div class="default-modal__body orthopedist-modal__body">
        <div v-if="!message" class="orthopedist-modal__input-fields">
          <InputComponent
            v-model="form.title.value"
            title="Имя"
            :subtitle="form.title.message"
            :error="form.title.error"
            modified
            required
          />
          <InputComponent
            v-model="form.phone.value"
            title="Телефон"
            :subtitle="form.phone.message"
            :error="form.phone.error"
            mask="+7 (###) ### - ## - ##"
            modified
            required
            is-phone
          />
          <!--          <InputComponent-->
          <!--            v-model="form.date.value"-->
          <!--            title="Дата и время"-->
          <!--            placeholder="22.10.2022 в 15:30"-->
          <!--            :subtitle="form.date.message"-->
          <!--            :error="form.date.error"-->
          <!--            modified-->
          <!--            required-->
          <!--          />-->
          <DateComponent
            v-if="isMounted"
            v-model.trim="form.date.value"
            :error="form.date.error"
            title="Дата и время"
            placeholder="22.10.2022 в 15:30"
            required
            mode="datetime"
            :minDate="new Date()"
          />
          <button @click="submit" class="btn btn--md btn--red" type="button">
            <IconComponent v-if="loading" name="loading" />
            <span v-else>Записаться</span>
          </button>
          <span class="orthopedist-modal__terms">
            Нажимая на кнопку, вы соглашаетесь с <a href="/files/terms.pdf">условиями использования</a>
          </span>
        </div>
        <span class="orthopedist-modal__message" v-else>
          {{ message }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import IconComponent from "components/IconComponent.vue";
import InputComponent from "components/inputs/InputComponent.vue";
import CLAIM_CREATE from "@/graphql/mutations/ClaimCreate.graphql";
import moment from "moment/moment";

export default {
  name: "OrthopedistModal",
  data() {
    return {
      step: 0,
      message: null,
      loading: false,
      isMounted: false,
      form: {
        title: {
          value: undefined,
          message: undefined,
          error: undefined,
        },
        phone: {
          value: undefined,
          message: undefined,
          error: undefined,
        },
        date: {
          value: undefined,
          message: undefined,
          error: undefined,
        },
      },
    };
  },
  mounted() {
    this.isMounted = true;
  },
  methods: {
    submit() {
      if (!this.loading) {
        this.loading = true;
        this.$apollo.provider.defaultClient
          .mutate({
            mutation: CLAIM_CREATE,
            variables: {
              title: this.form.title.value || undefined,
              phone: this.form.phone.value || undefined,
              date: this.form.date.value
                ? moment(this.form.date.value).format("YYYY-MM-DD HH:mm:ss")
                : undefined,
              type: this.$store.state._types.ORTHOPEDIST,
            },
            context: {
              headers: {
                Authorization: "Bearer " + this.$store.state.apollo_token,
              },
            },
          })
          .then(({ data }) => {
            if (data && data.ClaimCreate) {
              this.message = data.ClaimCreate.message;
            }
            this.loading = false;
            setTimeout(() => {
              this.$emit("close");
            }, 10000);
          })
          .catch(({ graphQLErrors }) => {
            this.loading = false;
            this.parseGqlErrors(graphQLErrors);
          });
      }
    },
    parseGqlErrors(graphQLErrors) {
      graphQLErrors.forEach((err) => {
        if (err.extensions.category === "validation") {
          Object.keys(err.extensions.validation).forEach((key) => {
            if (this.form[key]) {
              this.form[key].error = err.extensions.validation[key][0];
            }
          });
        }
      });
    },
  },
  components: {
    InputComponent,
    IconComponent,
    DateComponent: () => import("components/inputs/DateComponent.vue"),
  },
};
</script>

<style lang="stylus">
.orthopedist-modal {
  width 100%
  max-width 710px
  display grid
  grid-template-columns minmax(250px, 305fr) 405fr
  +below(620px) {
    grid-template-columns 1fr
  }

  &--message {
    grid-template-columns 1fr

    & ^[0]__img {
      display none
    }
  }

  &__img {
    object-fit cover
    object-position center
    border-radius: var(--main-radius) 0 0 var(--main-radius)

    &:not(&--mobile) {
      +below(620px) {
        display none
      }
    }

    &--mobile {
      height 205px
      border-radius var(--main-radius) var(--main-radius) 0 0
      +above(621px) {
        display none
      }
    }
  }

  &__body {
    align-items stretch
  }

  &__input-fields {
    display flex
    flex-direction column
    gap: 20px
  }

  &__terms {
    font-size: 0.75em
    line-height: 14px;
    color: var(--dark-light)

    a {
      border-bottom 1px solid
    }
  }

  &__message {
    padding 30px
    width 100%
    border-radius var(--big-radius)
    color var(--green-dark)
    background: var(--green-o05)
    border: 1px solid var(--green-o15)
    text-align center
  }
}
</style>
